<template>
  <div id='sl-history-log-modal-container' class='sl-flex-only'>
    <div class='sl-history-log-modal-content' style='max-width: 600px;'>
      <div class='sl-flex-justify-content-space-btwn'>
        <div class='sl-w-750px sl-font-size-16px sl-mt-10px'>{{ `${tableTitle} Instructions` }}</div>
        <div class='sl-w-100px sl-mb-20px sl-flex-justify-content-end'>
          <CloseIcon class='figma-icon' @click="$emit('closeModal')" id='sl-history-log-close'/>
        </div>
      </div>
      <div class='sl-border-t-2px-primary-blue sl-border-b-2px sl-ptb-20px'>
        <!-- Add new table instructions in alphabetical order  -->
        <AdjacentPropertyInstructions
          v-if="tableName==='adjacent property'"
        />
        <AdjoiningPropertiesInstructions
          v-else-if="tableName==='adjoining properties'"
        />
        <AerialPhotoInstructions
          v-else-if="tableName==='aerial photo'"
          @import-complete="$emit('importComplete')"
        />
        <HistoricalTopographicalInstructions
          v-else-if="tableName==='historical topographical'"
          @import-complete="$emit('importComplete')"
        />
        <EsaSubjectPropertyInstructions
          v-else-if="tableName==='esa subject property'"
          @import-complete="$emit('importComplete')"
        />
        <PcaImportInstructions
          v-else-if="['Bed Types', 'Commercial Tenant Spaces',
            'multi hvac equipment', 'Observed Spaces'].includes(tableName)"
          :tableName="tableName"
        />
        <RadiusReportDataInstructions
          v-else-if="tableName==='radius report data'"
          @import-complete="$emit('importComplete')"
          @delete-all="$emit('deleteAll')"
        />
        <RadonResultsLongTermComparisonInstructions
          v-else-if="tableName==='radon results long term comparison'"
        />
        <RadonResultsShortTermComparisonInstructions
          v-else-if="tableName==='radon results short term comparison'"
        />
        <SanbornFireInstructions
          v-else-if="tableName==='sanborn fire'"
          @import-complete="$emit('importComplete')"
        />
        <SitesOfConcernInstructions
          v-else-if="tableName==='sites of concern'"
        />
        <UserResponsibilitiesInstructions
          v-else-if="tableName==='user responsibilities'"
        />
        <div v-else class='sl-font-size-16px disabled-font'>No instructions available.</div>
      </div>
    </div>
  </div>

</template>
<script>
import CloseIcon from '../../../../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';
import AdjacentPropertyInstructions from './AdjacentPropertyInstructions.vue';
import AdjoiningPropertiesInstructions from './AdjoiningPropertiesInstructions.vue';
import AerialPhotoInstructions from './AerialPhotoInstructions.vue';
import EsaSubjectPropertyInstructions from './EsaSubjectPropertyInstructions.vue';
import HistoricalTopographicalInstructions from './HistoricalTopographicalInstructions.vue';
import PcaImportInstructions from './PcaImportInstructions.vue';
import RadiusReportDataInstructions from './RadiusReportDataInstructions.vue';
import RadonResultsLongTermComparisonInstructions from './RadonResultsLongTermComparisonInstructions.vue';
import RadonResultsShortTermComparisonInstructions from './RadonResultsShortTermComparisonInstructions.vue';
import SanbornFireInstructions from './SanbornFireInstructions.vue';
import SitesOfConcernInstructions from './SitesOfConcernInstructions.vue';
import UserResponsibilitiesInstructions from './UserResponsibilitiesInstructions.vue';

export default {
  props: ['tableName', 'tableTitle'],

  components: {
    CloseIcon,
    AdjacentPropertyInstructions,
    AdjoiningPropertiesInstructions,
    AerialPhotoInstructions,
    EsaSubjectPropertyInstructions,
    HistoricalTopographicalInstructions,
    PcaImportInstructions,
    RadiusReportDataInstructions,
    RadonResultsLongTermComparisonInstructions,
    RadonResultsShortTermComparisonInstructions,
    SanbornFireInstructions,
    SitesOfConcernInstructions,
    UserResponsibilitiesInstructions,
  },
};
</script>
<style>
  .report-table-instructions {
    line-height: 20px !important;
    color: #333E47;
    margin-top: 5px;
    font-size: 14px;
  }
  .instructions-list {
    margin-left: 20px;
  }
  .instuctions-list-item {
    list-style-type: disc;
    margin-left: 10px;
  }
</style>
