<template>
  <div id="sl-project-doctype-options" :data-doctypes="sortedProjectDoctypesOptions"></div>
  <div class="nl-container" style='height: unset;'>
    <div class="nl-center-background" style='width: 1140px;'>
      <div class="sl-client-doc-upload-container">
        <div class="nl-header">
          <partnerTriLogo />
        </div>
        <div v-if="client" class="sl-client-portal-title">
          {{ client.name }} - Property Information Upload & Status Portal
        </div>
        <div v-if="project_full_name_with_address" class="sl-project-info">
          {{ project_full_name_with_address }}
        </div>
        <dropzoneFilesNotAdded />
        <div class="sl-text-align-center" style='margin-top: 40px;'></div>
        <div class='sl-cp-doc-container-main' style='padding: 0;'>
          <div class='sl-cp-doc-subcontainer-center'>
            <projectScopeDocDropzone :project_scope_id="project_scope_id"/>
            <CollapsibleSection barText="Info Receipt Status">
              <div class='sl-info-receipt-status'>
                <div style='width: 200px'>
                  <div class='sl-small-blue-header'>Required Requests</div>
                  <div style='font-size: 32px;'>{{ total_required_doctypes_uploaded }} of {{ number_total_req_doc_types }}</div>
                </div>
                <div style='width: 200px'>
                  <div class='sl-small-blue-header'>General Requests</div>
                  <div style='font-size: 32px;'>{{ total_additional_doctypes_uploaded }} of {{ number_total_add_doc_types }}</div>
                </div>
                <div style='width: 200px'>
                  <div class='sl-small-blue-header'>Overall Info Receipt</div>
                  <div style='font-size: 32px;'>{{ total_required_doctypes_uploaded + total_additional_doctypes_uploaded}} of {{ number_total_req_doc_types +  number_total_add_doc_types }}</div>
                </div>
              </div>
              <div class='sl-client-portal-important-date-info' v-if='shouldRenderDueDate'>
                Incomplete data receipt by <b>{{document_due_date}}</b> may delay Partner's ability to deliver as scheduled for the <b>{{project_due_date}}</b> delivery date. 
              </div>
            </CollapsibleSection>  
            <CollapsibleSection :barText="notIdentifiedFilesText">
              <div v-if="previous_document_uploads.length>0">
                <div id='sl-cp-client-docs-table-container' style='margin-top: 10px; display: flex; justify-content: center;'>
                  <div class='sl-cp-client-docs-table' v-for="(docs, index) in splitDocuments" :key="index">
                    <div class='sl-cp-uploaded-docs-table'>
                      <div class='sl-cp-table-header'>
                        <div class='sl-cp-row'>
                          <div class='sl-cp-cell no-width'>File Name</div>
                          <div class='sl-cp-cell no-width'>File Type</div>
                        </div>
                      </div>
                      <div class='sl-cp-document-rows'>
                        <div class='sl-cp-row' v-for="(doc, index) in docs" :key="index">
                          <div class='sl-cp-cell no-width' :title="doc.original_document_file_name">{{ truncate(doc.original_document_file_name, 29) }}</div>
                          <div class='sl-cp-cell no-width'>
                            <select :name="'document_type_'+doc.id" :id="'document_type_'+doc.id" class='sl-figma-select-variant-2 sl-select-overflow' data_field= 'document_type' :document_id="doc.id" @input="selectInputChange">
                              <option value="">Make a Selection</option>
                              <option v-for="option in sortedProjectDoctypesOptions" :value="option" :key="option" :selected="doc.document_type === option">{{option}}</option>
                            </select>
                          <textarea :name="'document_type_ta_'+doc.id" :id="'document_type_ta_'+doc.id" class='sl-hidden-other-doc-row sl-val-textfield' data_field='document_type' :document_id="doc.id" @input="textareaInputChange"></textarea>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CollapsibleSection>
            <CollapsibleSection :barText="'Icons Key'">
              <div style='display: flex; justify-content: space-evenly; margin-top: 10px;'>
                <div class='sl-icon-key'>
                  <span class='sl-icon-key-text'>Not Uploaded/Missing =</span>
                  <StatusIcon :status="'Missing'"/>
                </div>
                <div class='sl-icon-key'>
                  <span class='sl-icon-key-text'>Awaiting Review =</span>
                  <StatusIcon :status="'Review Needed'"/>
                </div>
                <div class='sl-icon-key'>
                  <span class='sl-icon-key-text'>Approved =</span>
                  <StatusIcon :status="'Approved'"/>
                </div>
                <div class='sl-icon-key'>
                  <span class='sl-icon-key-text'>Not Approved =</span>
                  <StatusIcon :status="'Not Approved'"/>
                </div>
                <div class='sl-icon-key'>
                  <span class='sl-icon-key-text'>Not Applicable =</span>
                  <StatusIcon :status="'Not Applicable'"/>
                </div>
              </div>
            </CollapsibleSection>
            <CollapsibleSection :barText="requiredFilesText">
              <div id='sl-cp-client-docs-table-container' style='margin-top: 10px; display: flex; justify-content: center;'>
                <div class='sl-cp-client-docs-table' v-for="(docs, index) in splitReqDocumentTypes" :key="index">
                  <div class='sl-cp-table'>
                    <div class='sl-cp-table-header'>
                      <div class='sl-cp-row'>
                        <div class='sl-cp-cell'>File Type</div>
                        <div class='sl-cp-cell'>File Name</div>
                        <div class='sl-cp-cell small-column'>Status</div>
                      </div>
                    </div>
                    <div class='sl-cp-document-rows'>
                      <div class='sl-cp-row' v-for="(doc_type, index) in docs" :key="index">
                        <div class='sl-cp-cell' :title="doc_type.name">{{ truncate(doc_type.name, 26) }}</div>
                        <!-- Conditionally render dropdown if status is 'Missing' -->
                        <div class='sl-cp-cell' v-if="doc_type.status === 'Missing'">
                          <select 
                            v-model="doc_type.selected_value" 
                            @change="handleSelection(doc_type, $event)" 
                            :class="{'dark-charcoal-text': doc_type.selected_value === 'Not Applicable', 'red-text': doc_type.selected_value !== 'Not Applicable'}" 
                            class='sl-figma-select-variant-1'>
                            <option :value="doc_type.original_document_file_names">{{ truncate(doc_type.original_document_file_names, 26) }}</option>
                            <option value="Not Applicable">Not Applicable</option>
                          </select>
                        </div>
                        <div class='sl-cp-cell' v-else :title="doc_type.original_document_file_names" :class="{ 'missing': doc_type.status === 'Missing' }">
                          {{ truncate(doc_type.original_document_file_names, 26) }}
                        </div>
                        <div class='sl-cp-cell small-column'>
                          <span style='margin-left: 8px;' :title="computedStatus(doc_type)"><StatusIcon :status="computedStatus(doc_type)"/></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CollapsibleSection>
            <CollapsibleSection :barText="generalInfoFilesText">
              <div id='sl-cp-client-docs-table-container' style='margin-top: 10px; display: flex;'>
                <div class='sl-cp-client-docs-table' v-for="(docs, index) in splitAddDocumentTypes" :key="index">
                  <div class='sl-cp-table'>
                    <div class='sl-cp-table-header'>
                      <div class='sl-cp-row'>
                        <div class='sl-cp-cell'>File Type</div>
                        <div class='sl-cp-cell'>File Name</div>
                        <div class='sl-cp-cell small-column'>Status</div>
                      </div>
                    </div>
                    <div class='sl-cp-document-rows'>
                      <div class='sl-cp-row' v-for="(doc_type, index) in docs" :key="index">
                        <div class='sl-cp-cell' :title="doc_type.name">{{ truncate(doc_type.name, 26) }}</div>
                        <!-- Conditionally render dropdown if status is 'Missing' -->
                        <div class='sl-cp-cell' v-if="doc_type.status === 'Missing'">
                          <select 
                            v-model="doc_type.selected_value" 
                            @change="handleSelection(doc_type, $event)" 
                            :class="{'dark-charcoal-text': doc_type.selected_value === 'Not Applicable', 'red-text': doc_type.selected_value !== 'Not Applicable'}" 
                            class='sl-figma-select-variant-1'>
                            <option :value="doc_type.original_document_file_names">{{ truncate(doc_type.original_document_file_names, 26) }}</option>
                            <option value="Not Applicable">Not Applicable</option>
                          </select>
                        </div>
                        <div class='sl-cp-cell' v-else :title="doc_type.original_document_file_names" :class="{ 'missing': doc_type.status === 'Missing' }">
                          {{ truncate(doc_type.original_document_file_names, 26) }}
                        </div>
                        <div class='sl-cp-cell small-column'>
                          <span style='margin-left: 8px;' :title="computedStatus(doc_type)"><StatusIcon :status="computedStatus(doc_type)"/></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CollapsibleSection>
          </div>
        </div>
        <div>
          <div class='sl-client-portal-bold-subtext' style='margin-top: 20px;'>Questions or concerns?</div>
          <div class='sl-client-portal-bold-subtext' style='font-weight: 400;' v-if="shouldRenderRM">
            Email {{ rm_name }} at <a :href="rm_email_href" class='sl-client-help-link'>{{ rm_email }}</a>
          </div>
          <div class='sl-client-portal-bold-subtext' style='font-weight: 400;'>
            <span v-if="shouldRenderRM" style='margin-right: 5px;'>or</span><a href="mailto:Admin@sitelynx.net" class='sl-client-help-link'>Admin@sitelynx.net</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CollapsibleSection from '../../components/CollapsibleSection.vue';
import partnerTriLogo from '../../../../assets/images/logos/partner_tri_logo.svg';
import projectScopeDocDropzone from '../../components/project_scope_doc/ProjectScopeDocDropzone.vue';
import dropzoneFilesNotAdded from '../../components/project_scope_doc/DropzoneFilesNotAdded.vue';
import StatusIcon from '../../components/icons/StatusIcon.vue';

export default {
  components: {
    partnerTriLogo,
    projectScopeDocDropzone,
    dropzoneFilesNotAdded,
    StatusIcon,
    CollapsibleSection,
  },

  data() {
    return {
      project: null,
      project_scope_document: null,
      project_scope_id: null,
      client: null,
      project_doctypes: [],
      project_doctypes_options: null,
      previous_document_uploads: [],
      required_document_types: [],
      additional_document_types: [],
      number_total_add_doc_types: 0,
      number_total_req_doc_types: 0,
      rm_name: null,
      rm_email: null,
      rm_email_href: null,
      project_due_date: null,
      document_due_date: null,
      total_required_doctypes_uploaded: 0,
      total_additional_doctypes_uploaded: 0,
    };
  },

  async created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      const urlParams = new URLSearchParams(window.location.search);
      const credentials = urlParams.get('credentials');

      await axios.get('/client_portal/return_document_upload_data', {
        params: {
          credentials,
        },
      })
      .then((response) => {
        this.project = response.data.project;
        this.project_scope_document = response.data.project_scope_document;
        this.project_scope_id = response.data.project_scope_id;
        this.client = response.data.client;
        this.project_doctypes = response.data.project_doctypes;
        this.project_doctypes_options = response.data.project_doctypes_options;
        this.previous_document_uploads = response.data.previous_document_uploads;
        this.required_document_types = response.data.required_document_types;
        this.additional_document_types = response.data.additional_document_types;
        this.number_total_add_doc_types = response.data.number_total_add_doc_types;
        this.number_total_req_doc_types = response.data.number_total_req_doc_types;
        this.rm_name = response.data.rm_name;
        this.rm_email = response.data.rm_email;
        this.rm_email_href = response.data.rm_email_href;
        this.project_due_date = response.data.project_due_date;
        this.document_due_date = response.data.document_due_date;
        this.project_full_name_with_address = response.data.project_full_name_with_address;
        this.total_required_doctypes_uploaded = response.data.total_required_doctypes_uploaded;
        this.total_additional_doctypes_uploaded = response.data.total_additional_doctypes_uploaded;
      }).catch((error) => {
        console.log(error);
      });
    },
    selectInputChange(e) {
      const { target } = e;
      const data = target.value;
      // The view model.
      const vm = this;
      // When 'Other' is selected via doc_type, don't send data, instead show hidden ta so custom doc_type can be input
      if (data === 'Other') {
        target.style.display = 'none';
        const element_id = target.getAttribute('id');
        console.log(target.getAttribute('id'));
        const new_id = element_id.replace('document_type_', 'document_type_ta_');
        document.getElementById(new_id).style.display = 'block';
      } else {
        const document_id = target.getAttribute('document_id');
        const data_field = target.getAttribute('data_field');
        const { project_scope_id } = vm;
        this.client_portal_doc_update(data, document_id, data_field, project_scope_id);
      }
    },
    // when text area changes (from other), send the data
    textareaInputChange(e) {
      const { target } = e;
      const data = target.value;
      const vm = this;
      const document_id = target.getAttribute('document_id');
      const data_field = target.getAttribute('data_field');
      const { project_scope_id } = vm;
      this.client_portal_doc_update(data, document_id, data_field, project_scope_id);
    },
    handleSelection(doc, event) {
      const selectedValue = event.target.value;
      console.log(selectedValue); // This will log any selection to the console.
      
      // Check if 'Not Applicable' was selected and perform actions as necessary
      if (selectedValue === 'Not Applicable') {
        console.log('Not applicable selected for:', doc.id);
        $jq.ajax({
          type: 'POST',
          url: '/project_scope_documents/doctype_na',
          data: {
            doctype_id: doc.id
          },
          success: function() {
            this.loadData();
          }.bind(this), // Binding 'this' to the Vue instance
        });
      }
    },

    // Sends ajax call to update document
    // also has debouncer so that when typing it only sends 1 api call at the end
    async client_portal_doc_update(data, document_id, data_field, project_scope_id) {
      // this time out is so that when typing in box it doesn't send each letter
      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        $jq.ajax({
          type: 'POST',
          url: '/project_scope_documents/partial_update_document',
          data: {
            data,
            document_id,
            data_field,
            project_scope_id,
          },
          success: function() {
            this.loadData();
          }.bind(this), // Binding 'this' to the Vue instance
        });
      }, 200); // delay
    },

    truncate(value, limit) {
      if (value.length > limit) {
        return value.substring(0, limit) + '...';
      } else {
        return value;
      }
    },
    computedStatus(doc_type) {
      return doc_type.not_applicable ? 'Not Applicable' : doc_type.status;
    },
  },
  computed: {
    shouldRenderRM() {
      // Check if both rm_name and rm_email_href exist and are not null and not empty strings
      return (
        this.rm_name !== null
          && this.rm_name !== undefined
          && this.rm_name.trim() !== ''
          && this.rm_email_href !== null
          && this.rm_email_href !== undefined
          && this.rm_email_href.trim() !== ''
      );
    },
    shouldRenderDueDate() {
      // Check if both project_due_date and document_due_date exist and are not null and not empty strings
      return (
        this.project_due_date !== null
          && this.project_due_date !== undefined
          && this.project_due_date.trim() !== ''
          && this.document_due_date !== null
          && this.document_due_date !== undefined
          && this.document_due_date.trim() !== ''
      );
    },
    notIdentifiedFilesText() {
      return `Uploaded Files Not Yet Identified (${this.previous_document_uploads.length} Documents)`;
    },
    requiredFilesText() {
      return `Required Information Requests (${this.total_required_doctypes_uploaded} of ${this.number_total_req_doc_types} Documents)`;
    },
    generalInfoFilesText() {
      return `General Information Requests (${this.total_additional_doctypes_uploaded} of ${this.number_total_add_doc_types} Documents)`;
    },
    splitReqDocumentTypes() {
      const mid = Math.ceil(this.required_document_types.length / 2);
      return [
        this.required_document_types.slice(0, mid),
        this.required_document_types.slice(mid)
      ];
    },
    splitAddDocumentTypes() {
      const mid = Math.ceil(this.additional_document_types.length / 2);
      return [
        this.additional_document_types.slice(0, mid),
        this.additional_document_types.slice(mid)
      ];
    },
    splitDocuments() {
      const mid = Math.ceil(this.previous_document_uploads.length / 2);
      return [
        this.previous_document_uploads.slice(0, mid),
        this.previous_document_uploads.slice(mid)
      ];
    },
    sortedProjectDoctypesOptions() {
      return this.project_doctypes_options.slice().sort();
    },
  },
};

</script>

<style>

.sl-small-blue-header {
  border: solid var(--primary-blue);
  border-width: 2px 0 2px 0;
  background: var(--white);
  color: var(--primary-blue);
  font-size: 14px;
  font-weight: bold;
}

.sl-info-receipt-status {
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
}

.sl-cp-cell {
  flex: 1; padding: 5px; text-align: left;
}

#sl-cp-client-docs-table-container {
  display: flex;
  justify-content: space-between;
}

.sl-cp-client-docs-table {
  flex: 1;
  margin-right: 10px;
}

.sl-cp-table {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.sl-cp-uploaded-docs-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.sl-cp-table-header,
.sl-cp-document-rows {
  display: contents;
}

.sl-cp-cell {
  padding: 5px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sl-cp-cell.status {
  display: flex;
  align-items: center;
}

.sl-icon-key {
  display: flex;
  align-items: center;
}

.sl-icon-key-text {
  margin-right: 5px;
}

.dark-charcoal-text {
  color: var(--dark-charcoal); /* Ensure this overrides any other color settings */
}

.red-text {
  color: var(--error); /* Default color, assuming red is the default as per your description */
}

</style>
